<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Avatar List</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['avatars/create'])"
                       :to="'/avatars/create/'"
                       class="btn btn-info btn-wd" wide>
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>
        <div>
          <general-data-table
            ref="table"
            :sortable="true"
            :row-key="'id'"
            :key="componentKey"
            :api-url="'avatars/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <template>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['avatars/update']) ||
                                     $store.getters['auth/checkAccessOwner']( scope.row.creator_id)"
                               v-tooltip.top-center="'Edit'"
                               class="btn-warning btn-simple btn-link"
                               :to="'/avatars/edit/'+scope.row.id">
                    <i class="fa fa-edit"></i>
                  </router-link>
                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['avatars/delete']) ||
                           $store.getters['auth/checkAccessOwner'](  scope.row.creator_id)"
                     v-tooltip.top-center="'Delete'"
                     class="btn-danger btn-simple btn-link"
                     @click="openDeleteModal(scope.row.id)">
                    <i class="fa fa-times"></i></a>
                </template>


              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this avatar?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import LSwitch from "../../components/Switch";
import QrcodeModal from "../../components/Modals/QrcodeModal";

export default {
  components: {
    LSwitch,
    GeneralDataTable,
    DeleteModal,
    QrcodeModal,
    [TableColumn.name]: TableColumn,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Name', value: 'name', minWidth: '200', align: 'center'},
      ],
      qrCodeModalVisibility: false,
      deleteModalVisibility: false,
      currentObject: {},
      toDeleteId: undefined,
      componentKey: 0,
    }
  },
  methods: {
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    async confirmDeleteModal() {
      try {
        let data = {
          'id': this.toDeleteId,
        }
        await this.axios.delete("avatars/delete", {headers: {}, data});
        this.$refs.table.getData("updateData");
        this.$notify({
          message: "Avatar deleted successfully",
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },
  },
}
</script>

